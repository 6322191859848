export default ($t) => ({
  title: "ChineseAuction",
  type: "object",
  expanded: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      expanded: true,
      default: true,
    },
    points_reward_percent: {
      title: $t(
        "edit_campaign.chinese_auction_setting_title_points_reward_percent",
        "Points Reward Percent"
      ),
      tooltipText: $t(
        "edit_campaign.chinese_auction_setting_tooltip_points_reward_percent",
        "Percentage of the total funds that will be rewarded to the winner of the auction"
      ),
      type: "number",
      default: 0,
    },
    cost_per_point: {
      title: $t("edit_campaign.chinese_auction_setting_title_cost_per_point", "Cost Per Point"),
      tooltipText: $t(
        "edit_campaign.chinese_auction_setting_tooltip_cost_per_point",
        "Cost per point in the currency specified below"
      ),
      type: "number",
      default: 0,
    },
    cost_per_point_currency: {
      title: $t(
        "edit_campaign.chinese_auction_setting_title_cost_per_point_currency",
        "Cost Per Point Currency"
      ),
      tooltipText: $t(
        "edit_campaign.chinese_auction_setting_tooltip_cost_per_point_currency",
        "Currency used for the cost per point"
      ),
      type: "string",
      default: "usd",
    },
    max_purchasable_points: {
      title: $t(
        "edit_campaign.chinese_auction_setting_title_max_purchasable_points",
        "Max Purchasable Points"
      ),
      tooltipText: $t(
        "edit_campaign.chinese_auction_setting_tooltip_max_purchasable_points",
        "Maximum number of points that can be purchased"
      ),
      type: "number",
      default: 0,
    },
    points_reward_percent_children: {
      title: $t(
        "edit_campaign.chinese_auction_setting_title_points_reward_percent_children",
        "Points Reward Percent Children"
      ),
      tooltipText: $t(
        "edit_campaign.chinese_auction_setting_tooltip_points_reward_percent_children",
        "Percentage of the total funds that will be rewarded to the winner of the auction for children"
      ),
      type: "number",
      default: 0,
    },
  },
});
