<script setup>
import { computed, ref } from "vue";
import CampaignEmailToggle from "./campaign-email-toggle.vue";
import OrgNotificationFromEmailLink from "@/views/account/subcomponents/orgNotificationFromEmailLink.vue";

const props = defineProps({
  currentEmailType: {
    type: String,
    default: "receipt_email",
  },
  showReceiptToggles: {
    type: Boolean,
    default: false,
  },
  showBtns: Boolean,
  emailTemplate: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits([
  "update:showBtns",
  "change-image",
  "show-edit-modal",
  "reset-all-template-to-default",
]);

const emailTemplateFile = ref(null);
function onClickImage() {
  emailTemplateFile.value?.click();
}

const showUploadImageBtn = computed(() => {
  if (props.currentEmailType === "team_confirmation_email") {
    return false;
  }

  if (props.currentEmailType === "donation_success_invite_friends_email") {
    return false;
  }

  if (props.currentEmailType === "email_to_matched_donor") {
    return false;
  }

  return true;
});
</script>

<template>
  <div>
    <CampaignEmailToggle v-if="showReceiptToggles" class="mb-4" :email-type="currentEmailType" />
    <OrgNotificationFromEmailLink class="mb-4" />
    <div class="tw-w-flex tw-w-full tw-items-start">
      <p v-if="currentEmailType === 'confirmation_email'" class="m-auto text-center">
        {{
          $t(
            "edit_campaign.email_subheading_confirmation",
            "Only for all or nothing campaigns and pledge donations"
          )
        }}
      </p>
      <div
        class="template-view"
        @mouseover="emit('update:showBtns', true)"
        @mouseleave="emit('update:showBtns', false)"
      >
        <div class="template">
          <transition name="fade">
            <div class="template-btn-group" v-show="showBtns">
              <div class="btn-wrap" v-if="showUploadImageBtn">
                <button
                  class="bttn bttn--lg bttn--blue"
                  :title="$t('dashboard.email_template_change_image')"
                  @click="onClickImage"
                >
                  <input
                    type="file"
                    @change="emit('change-image', $event)"
                    ref="emailTemplateFile"
                    style="display: none"
                  />
                  {{ $t("edit_campaign.email_template_update_image") }}
                </button>
              </div>
              <div class="btn-wrap">
                <button class="bttn bttn--lg bttn--blue" @click="emit('show-edit-modal')">
                  {{ $t("edit_campaign.email_template_edit_content") }}
                </button>
              </div>
              <div class="btn-wrap">
                <button
                  class="bttn bttn--lg bttn--orange el-right"
                  @click="emit('reset-all-template-to-default')"
                >
                  {{ $t("edit_campaign.email_template_reset_all") }}
                </button>
              </div>
            </div>
          </transition>
          <div
            class="body"
            v-if="emailTemplate"
            v-html="emailTemplate.attributes.preview_html_body"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
