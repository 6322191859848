<template>
  <div>
    <b-card class="!tw-border-0" no-body>
      <b-tabs pills card vertical content-class="tw-p-0">
        <b-tab
          @click="changeEmailType('confirmation_email')"
          :title="$t('edit_campaign.email_template_confirmation', 'Donation confirmation')"
        >
          <b-overlay :show="loadingEmailTemplates || updateEmail" rounded="sm">
            <CampaignEmailTemplateSettings
              v-if="emailTemplates"
              :currentEmailType="currentEmailType"
              :showReceiptToggles="showReceiptToggles"
              :showBtns.sync="showBtns"
              :emailTemplate="emailTemplate"
              @change-image="onChangeImage"
              @show-edit-modal="showEditModal"
              @reset-all-template-to-default="resetAllTemplateToDefault"
            />
          </b-overlay>
        </b-tab>
        <b-tab
          active
          no-body
          @click="changeEmailType('receipt_email')"
          :title="$t('edit_campaign.email_template_receipt', 'Receipt email')"
        >
          <b-overlay :show="loadingEmailTemplates || updateEmail" rounded="sm">
            <b-card no-body>
              <b-tabs card justified content-class="tw-mt-3">
                <b-tab
                  v-if="emailTemplates"
                  :title="$t('edit_campaign.email_template_title', 'Email templates and settings')"
                  active
                >
                  <CampaignEmailTemplateSettings
                    :currentEmailType="currentEmailType"
                    :showReceiptToggles="showReceiptToggles"
                    :showBtns.sync="showBtns"
                    :emailTemplate="emailTemplate"
                    @change-image="onChangeImage"
                    @show-edit-modal="showEditModal"
                    @reset-all-template-to-default="resetAllTemplateToDefault"
                  />
                </b-tab>
                <b-tab
                  no-body
                  v-if="attachments.length"
                  :disabled="loadingEmailTemplates || updateEmail"
                  :title="$t('edit_campaign.receipt_preview', 'Receipts preview')"
                >
                  <div class="tw-flex tw-w-full tw-h-full tw-justify-center attachments-container">
                    <div v-for="atch in attachments" :key="atch.id">
                      <object :data="atch.src" type="application/pdf">
                        <iframe
                          :src="`https://docs.google.com/viewer?url=${atch.src}&embedded=true`"
                        ></iframe>
                      </object>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-overlay>
        </b-tab>
        <b-tab
          @click="changeEmailType('team_confirmation_email')"
          :title="
            $t('edit_campaign.email_template_team_confirmation_email', 'Team confirmation email')
          "
        >
          <b-overlay :show="loadingEmailTemplates || updateEmail" rounded="sm">
            <CampaignEmailTemplateSettings
              v-if="emailTemplates"
              :currentEmailType="currentEmailType"
              :showReceiptToggles="showReceiptToggles"
              :showBtns.sync="showBtns"
              :emailTemplate="emailTemplate"
              @change-image="onChangeImage"
              @show-edit-modal="showEditModal"
              @reset-all-template-to-default="resetAllTemplateToDefault"
            />
          </b-overlay>
        </b-tab>
        <b-tab
          @click="changeEmailType('donation_success_invite_friends_email')"
          :title="
            $t(
              'edit_campaign.email_template_donation_success_invite_friends_email',
              'Donation success invite friends email'
            )
          "
        >
          <b-overlay :show="loadingEmailTemplates || updateEmail" rounded="sm">
            <CampaignEmailTemplateSettings
              v-if="emailTemplates"
              :currentEmailType="currentEmailType"
              :showReceiptToggles="false"
              :showBtns.sync="showBtns"
              :emailTemplate="emailTemplate"
              @change-image="onChangeImage"
              @show-edit-modal="showEditModal"
              @reset-all-template-to-default="resetAllTemplateToDefault"
            />
          </b-overlay>
        </b-tab>
        <b-tab
          @click="changeEmailType('email_to_matched_donor')"
          :title="
            $t('edit_campaign.email_template_email_to_matched_donor', 'Email to matched donor')
          "
        >
          <b-overlay :show="loadingEmailTemplates || updateEmail" rounded="sm">
            <CampaignEmailTemplateSettings
              v-if="emailTemplates"
              :currentEmailType="currentEmailType"
              :showReceiptToggles="false"
              :showBtns.sync="showBtns"
              :emailTemplate="emailTemplate"
              @change-image="onChangeImage"
              @show-edit-modal="showEditModal"
              @reset-all-template-to-default="resetAllTemplateToDefault"
            />
          </b-overlay>
        </b-tab>
      </b-tabs>
    </b-card>

    <CampaignEmailModal ref="emailModal" @save-email-template="updateContentTemplate" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import router from "@/router";
import urls from "@/constants/urls.js";
import CampaignEmailModal from "./campaign-email-modal.vue";
import CampaignEmailTemplateSettings from "./campaign-email-template-settings.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "campaign-email-template",
  data() {
    return {
      showBtns: false,
      updateEmail: false,
      currentEmailType: "receipt_email",
      emailTemplateData: {
        data: {
          attributes: {
            custom_subject: "",
            custom_body: "",
          },
          relationships: {
            custom_content: [],
          },
        },
        included: [],
      },
      templateType: "",
      errors: [],
    };
  },

  components: {
    CampaignEmailModal,
    CampaignEmailTemplateSettings,
  },

  created: function () {
    this.$store.dispatch("getEmailTemplates");
  },

  mounted() {},

  methods: {
    changeEmailType(val) {
      this.currentEmailType = val;
    },

    showEditModal() {
      this.$refs.emailModal.openModal(this.emailTemplateData);
    },

    updateContentTemplate(template) {
      this.emailTemplateData.data.attributes = template.attributes;
      this.emailTemplateData.data.relationships = template.relationships;
      this.emailTemplateData.included = template.included;

      this.$refs.emailModal.setErrors([]);
      this.updateTemplate();
    },

    showError(e_1) {
      if (e_1.response.data) {
        if (e_1.response.data.error) {
          this.errors.push(e_1.response.data.error);
        } else {
          this.errors.push(this.$t("template.error_default"));
        }
      } else {
        this.errors.push(this.$t("template.error_default"));
      }
    },

    onChangeImage(e) {
      this.processFile(e);
    },

    async processFile(e) {
      this.errors = [];

      let fileInput = e.target.files[0];

      if (fileInput != null) {
        this.load = true;
        this.loadingImage = true;
        this.updateEmail = true;

        let form_data = new FormData();

        form_data.append("file", fileInput);

        try {
          const response = await axios.post(urls.loadImageQuill, form_data);

          const [included] = this.emailTemplateData.included;
          included.attributes.src = response.data.src;

          this.updateTemplate();
        } catch (e_1) {
          this.showError(e_1);
        } finally {
          this.updateEmail = false;
          this.loadingImage = false;
          this.load = false;
        }
      }
    },

    resetAllTemplateToDefault() {
      let orgId = localStorage.getItem("orgId");
      const templateType = this.emailTemplateData.data.attributes.title;

      this.updateEmail = true;
      axios
        .delete(
          urls.resetAllEmailTemplateById
            .replace(":orgId", orgId)
            .replace(":campaignId", router.currentRoute.params.id)
            .replace(":templateType", templateType)
        )
        .then(() => {
          this.load = false;
          this.updateEmail = false;
          this.$store.dispatch("getEmailTemplates");
        })
        .catch((e) => {
          this.updateEmail = false;
          this.showError(e);
        });
    },

    updateTemplate() {
      this.updateEmail = true;

      let orgId = localStorage.getItem("orgId");

      axios
        .post(
          urls.emailTemplateById
            .replace(":orgId", orgId)
            .replace(":campaignId", router.currentRoute.params.id)
            .replace(":templateType", this.templateType),
          this.emailTemplateData
        )
        .then(() => {
          this.$refs.emailModal.hideModal();
          this.$store.dispatch("getEmailTemplates");

          this.load = false;
          this.updateEmail = false;
        })
        .catch((e) => {
          this.load = false;
          this.updateEmail = false;
          this.showError(e);
          this.$refs.emailModal.setErrors(this.errors);
        });
    },
  },

  computed: {
    ...mapState({
      emailTemplate() {
        const template = this.getTemplateBytype(this.currentEmailType);

        if (template) {
          this.templateType = template.attributes.title;
          return template;
        }
      },
      emailTemplates: (state) => state.campaign.emailTemplates,
      loadingEmailTemplates: (state) => state.campaign.loadingEmailTemplates,
      type: (state) => state.campaign.campaign.data.attributes.type,
      showReceiptToggles: (state) => state.featureHide.showReceiptToggles,
    }),
    ...mapGetters(["getTemplateBytype"]),

    includedMap() {
      return this.emailTemplateData.included.reduce((res, el) => {
        res[`${el.id}_${el.type}`] = el.attributes;
        return res;
      }, {});
    },

    attachments() {
      return (this.emailTemplateData.data.relationships?.attachments?.data || []).map((el) => ({
        ...this.includedMap[`${el.id}_${el.type}`],
        id: el.id,
      }));
    },
  },

  watch: {
    emailTemplates({ included }) {
      this.emailTemplateData.included = [...included];
    },
    emailTemplate(templete) {
      this.emailTemplateData.data = Object.assign(this.emailTemplateData.data, templete);
    },
  },
});
</script>

<style lang="scss">
@import "@/styles/_vars.scss";

.attachments-container {
  & > div {
    width: 100%;
  }
  * object,
  * iframe {
    min-height: 1000px;
    height: 100%;
    width: 100%;
  }
}

.quill-editor .ql-container {
  height: 400px;
  position: relative;
}

.template {
  max-width: 600px;
  min-width: 500px;
  min-height: 200px;
  position: relative;
  img {
    max-width: 100% !important;
  }
  @media only screen and (min-width: 769px) {
    max-width: unset;
    min-width: unset;
    width: 100%;
  }
  @media only screen and (min-width: 769px) {
    * table {
      width: 100% !important;
    }
  }
}

.image-controll {
  position: relative;
  height: 30px;
  width: 30px;
  label {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #0084af;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 18px;
    &:hover {
      background-color: lighten(#0084af, 10%);
    }
  }

  .upload_new_file {
    background-image: url("../../../../assets/img/cloud_white.png");
    position: absolute;
    left: 0;
    top: 0;
  }
  .email-template-editor {
    .quill-editor .ql-container {
      height: 350px;
      position: relative;
    }
  }
  .email-template-editor .quill-editor .ql-container {
    height: 350px;
    position: relative;
  }
}

.email-type-btn {
  margin-bottom: 24px;
}

.reset-btn {
  margin-bottom: 24px;
}

.template-btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  .btn-wrap {
    margin: 5px 0;
  }
  label {
    margin: 0;
    cursor: pointer;
  }
}

.loadin-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 140px;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}
</style>
